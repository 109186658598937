import request from '@/utils/request'

export function login(param:{}) {
    return request({
        url: '/auth/login',
        headers: {
            isToken: false
        },
        method: 'post',
        data: param
    })
}

// 退出方法
export function logout() {
    return request({
        url: '/auth/logout',
        method: 'delete'
    })
}
