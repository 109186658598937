<template>
  <div class="font-contain">
    Copyright @ 2023~2025 Study-Note 版权所有 辽ICP备2023001537号-1
  </div>
</template>

<style scoped>
  .font-contain {
    width: 100%;
    height: 60px;
    background-color: #dedfe0;
    line-height:60px;
  }
</style>
