import { createRouter, createWebHashHistory } from "vue-router";
import LayoutIndex from "../layouts/LayoutIndex.vue";
const routes = [
    {
        path: '/',
        name: 'layout',
        component: LayoutIndex,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'home',
                component: () => import('@/views/HomeView.vue'),
            },
            {
                path: '/userInfo',
                name: 'userInfo',
                component: () => import('@/views/UserInfo.vue'),
            },
            {
                path: '/studyNote',
                name: 'studyNote',
                component: () => import('@/views/StudyNote.vue'),
            },
            {
                path: '/skillSquare',
                name: 'skillSquare',
                component: () => import('@/views/SkillSquare.vue'),
            }
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue'),
    },
    {
        path: '/skillSquare/share',
        name: 'share',
        component: () => import('@/views/Share.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
