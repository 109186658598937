import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
// 引入element-plus模块
import  ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {USER_TOKEN} from "@/store/contains/cacheKey";
import {Local} from "@/utils/storage";

const app = createApp(App)
// 引入图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}
app.use(store).use(router).use(ElementPlus).mount("#app");

// 导航守卫
router.beforeEach((to,from,next)=>{
    if(to.path=='/Login') return next();
    const token = Local.get(USER_TOKEN)
    if(!token) return next('/Login')
    next()
})

