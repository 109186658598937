
import {reactive, onMounted} from 'vue'
import {Local} from '@/utils/storage'
import {STUDY_SECONDS, STUDY_DATE, USER_INFO, USER_TOKEN} from '@/store/contains/cacheKey'
import { Female,Male } from "@icon-park/vue-next"
import router from '@/router'
import {ElMessage, UploadProps } from 'element-plus'

export default {
    name: 'layout',
    components: {
        Female,
        Male
    },
    setup() {
        const headers = {
            Authorization: "Bearer " + Local.get(USER_TOKEN)
        }
        const userInfo = reactive(Local.get(USER_INFO))

        const state = reactive({
            workingTimeFormat: '',
            studyTimer: {},
            studySeconds: 0,
        })

        // 初始化
        onMounted(() => {
            startStudy();
        })

        // 开始学习
        const startStudy = () => {
            let studyDate = Local.get(STUDY_DATE);
            let studySeconds = Local.get(STUDY_SECONDS);
            let nowDay = new Date().getDate();
            if (!studyDate) {
                studyDate = nowDay
                Local.set(STUDY_DATE, studyDate);
                studySeconds = 0;
                Local.set(STUDY_SECONDS, studySeconds);
            } else {
                if (studyDate !== nowDay) {
                    Local.set(STUDY_DATE, nowDay);
                    studySeconds = 0;
                    Local.set(STUDY_SECONDS, studySeconds);
                }
            }

            state.workingTimeFormat = formatSeconds(studySeconds)
            state.studyTimer = setInterval(() => {
                studySeconds++
                state.workingTimeFormat = formatSeconds(studySeconds)
                Local.set(STUDY_SECONDS, studySeconds);
            }, 1000)
        }

        // 时间格式化
        const formatSeconds = (seconds: number) => {
            let secondTime = 0 // 秒
            let minuteTime = 0 // 分
            let hourTime = 0 // 小时
            let result = ''

            if (seconds < 60) {
                secondTime = seconds
            } else {
                // 获取分钟，除以60取整数，得到整数分钟
                minuteTime = Math.floor(seconds / 60)
                // 获取秒数，秒数取佘，得到整数秒数
                secondTime = Math.floor(seconds % 60)
                // 如果分钟大于60，将分钟转换成小时
                if (minuteTime >= 60) {
                    // 获取小时，获取分钟除以60，得到整数小时
                    hourTime = Math.floor(minuteTime / 60)
                    // 获取小时后取佘的分，获取分钟除以60取佘的分
                    minuteTime = Math.floor(minuteTime % 60)
                }
            }

            result = hourTime + '小时' +
          ((minuteTime >= 10 ? minuteTime : '0' + minuteTime) + '分') +
          ((secondTime >= 10 ? secondTime : '0' + secondTime) + '秒')

            return result
        }

        // 路由改变
        const routerChange =(index: number) => {
            // let $obj = document.getElementsByClassName('menu-items');
            // for (let i = 0; i < $obj.length; i++) {
            //     if (i === index) {
            //         $obj[i].className = " menu-items menu-items-active";
            //     } else {
            //         $obj[i].className = " menu-items";
            //     }
            // }
            if (index === 0) {
                router.push({
                    path: '/'
                })
            } else if (index === 1) {
                router.push({
                    path: 'studyNote'
                })
            } else if (index === 2) {
                router.push({
                    path: 'userInfo'
                })
            } else if (index === 3) {
                window.open( "https://www.baidu.com");
            } else if (index === 4) {
                window.open( "https://www.study.manage.jj2ss.com");
            }
        }

        const beforeAvatarUpload: UploadProps['beforeUpload'] = (rawFile) => {
            if (rawFile.type !== 'image/png' && rawFile.type !== 'image/jpg' && rawFile.type !== 'image/jpeg'
                && rawFile.type !== 'image/gif') {
                ElMessage.error('不支持该文件类型')
                return false
            } else if (rawFile.size / 1024 / 1024 > 5) {
                ElMessage.error('图片大小不能大于5MB!')
                return false
            }
            return true
        }

        const handleAvatarSuccess =(res: { code: number; data: { url: any } }, file: any) => {
            // 如果上传成功
            if (res.code == 200) {
                userInfo.avatar = res.data;
                let localUserInfo = Local.get(USER_INFO)
                localUserInfo.avatar = res.data.url;
                Local.set(USER_INFO, localUserInfo);
            } else {
                ElMessage.error('系统内部错误，请联系姜神修改')
            }
        }

        // 函数返回
        return {
            state,
            routerChange,
            userInfo,
            beforeAvatarUpload,
            handleAvatarSuccess,
            headers
        }
    }
}
