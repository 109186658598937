<template>
    <div>
      <div>
        <layout-header></layout-header>
      </div>
      <div>
        <layout-main></layout-main>
      </div>
      <div>
        <layout-footer></layout-footer>
      </div>
    </div>
</template>

<script>
import LayoutMain from "@/layouts/LayoutMain";
import LayoutFooter from "@/layouts/LayoutFooter";
import LayoutHeader from "@/layouts/LayoutHeader";
export default {
    components: {LayoutHeader, LayoutFooter, LayoutMain}
}
</script>

<style>
</style>
