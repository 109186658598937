<template>
  <div id="app">
    <router-view/>
<!--    <div v-if="menuFlg">-->
<!--      <div>-->
<!--        <layout-header></layout-header>-->
<!--      </div>-->
<!--      <div>-->
<!--        <layout-main></layout-main>-->
<!--      </div>-->
<!--      <div>-->
<!--        <layout-footer></layout-footer>-->
<!--      </div>-->
<!--    </div>-->
<!--    <div v-if="!menuFlg">-->
<!--      <router-view></router-view>-->
<!--    </div>-->
  </div>
</template>

<script>
</script>

<style>
body {
  margin: 0px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
