import { createStore } from "vuex";

export default createStore({
    state: {
        token:'',
        nickName:'',
        sex:0,
    },
    mutations: {
        setUserInfo(state, userInfo) {
            state.nickName = userInfo.nickName;
            state.token = userInfo.access_token;
            state.sex = userInfo.sex;
        },
        setToken(state, token) {
            state.token = token;
        },
    },
    actions: {},
    getters: {},
    modules: {},
});
