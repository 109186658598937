/**
 * 本地缓存Key
 */
// 工作日期
export const STUDY_DATE = 'studyDate'
// 工作时间
export const STUDY_SECONDS = 'studySeconds'
// TOKEN
export const USER_TOKEN = 'User-Token'
// 用户信息
export const USER_INFO = 'User-Info'


