
import {logout} from '@/api/login'
import {Local} from "@/utils/storage";
import {USER_TOKEN} from "@/store/contains/cacheKey"
import router from '@/router';
export default {
    setup() {

        const doLogout = () => {
            logout().then(response => {
                Local.remove(USER_TOKEN);
                router.push({
                    name: 'login'
                })
            })
        }

        // 返回函数
        return {
            doLogout,
        }
    }
}
